<template>
  <div class="container">
    <div class="page-content">
      <div class="channel-panel">
        <table>
          <thead>
            <tr>
              <th colspan="2">ช่องทางการเชื่อมต่อ</th>
              <th>สถานะ</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="i in 100" :key="i">
              <td>
                <img
                  src="../../../assets/images/icon-shopee.png"
                  alt="Marketplace icon"
                  style="opacity: 0.5"
                />
              </td>
              <td>
                <div>ช่องทาง</div>
                <div class="f-12">ชื่อร้านค้า</div>
              </td>
              <td class="d-flex justify-content-between align-items-center h-100">
                <span>เชื่อมต่อสมบูรณ์</span>
                <font-awesome-icon :icon="['fas', 'ellipsis-v']" class="" />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="button-panel">
        <b-button
          class="w-100"
          :style="`background-color: ${$store.state.theme.themePrimaryColor};`"
          @click="$router.push('/connectmarketplace')"
          >เพิ่มช่องทางเชื่อมต่อ</b-button
        >
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
table {
  border-collapse: separate;
  border-spacing: 0 1rem;
  width: 100%;
  height: 100%;
  img {
    width: 50px;
    height: 50px;
    // border-radius: 50%;
  }
  td {
    padding: 5px 0px;
  }
}
.button-panel {
  position: absolute;
  width: calc(100% - 2rem);
  left: 0;
  margin: 1rem;
  bottom: 0;
}
.channel-panel {
  max-height: calc(100vh - 160px);
  overflow-y: auto;
  ::-webkit-scrollbar {
    display: none;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none;
  }
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none;
}
</style>
